import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/PageTemplate/index.ts";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Bibliography = makeShortcode("Bibliography");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Publications`}</h2>
    <Bibliography type="publications" mdxType="Bibliography" />
    <h2>{`Websites`}</h2>
    <Bibliography type="websites" mdxType="Bibliography" />
    <h2>{`Other`}</h2>
    <Bibliography type="other" mdxType="Bibliography" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      